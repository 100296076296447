
























































































































    .apiAuthorization {
		font-family: PingFangSC-Regular;
        height: 100%;
        .content{
            height: 100%;
        }
        .apiList {
            margin: 0.2rem 0;
			margin-bottom: 0;
			// min-height:calc(91.8vh + 54px);
			border-top-left-radius: 0.32rem;
			border-top-right-radius: 0.32rem;
			height: calc(100% - 0.2rem);

            li {
                padding: 0.2rem 0;
				margin: 0 0.4rem;
                border-bottom: 1px solid #F1F1F1;
                overflow: hidden;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .leftBox{
                    display: flex;
                    align-items: center;
                    .img-icon {
                        width: 0.61rem;
                        height: 0.61rem;
                        margin-right: 0.22rem;
                        // vertical-align: -0.2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &.hasBg img{
                            width: 70%;
                            height: 70%;
                        }
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }

                }

                .spot {
                    // float: right;

                    img {
                        width: 0.28rem;
                    }
                }

                span {
                    font-size: 0.25rem;
                    color: #02172E;
                }
            }

            li:last-child {
                // border-bottom: none;
            }
        }
    }

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;