.apiAuthorization {
  font-family: PingFangSC-Regular;
  height: 100%;
}
.apiAuthorization .content {
  height: 100%;
}
.apiAuthorization .apiList {
  margin: 0.2rem 0;
  margin-bottom: 0;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
  height: calc(100% - 0.2rem);
}
.apiAuthorization .apiList li {
  padding: 0.2rem 0;
  margin: 0 0.4rem;
  border-bottom: 1px solid #F1F1F1;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.apiAuthorization .apiList li .leftBox {
  display: flex;
  align-items: center;
}
.apiAuthorization .apiList li .leftBox .img-icon {
  width: 0.61rem;
  height: 0.61rem;
  margin-right: 0.22rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.apiAuthorization .apiList li .leftBox .img-icon.hasBg img {
  width: 70%;
  height: 70%;
}
.apiAuthorization .apiList li .leftBox .img-icon img {
  width: 100%;
  height: 100%;
}
.apiAuthorization .apiList li .spot img {
  width: 0.28rem;
}
.apiAuthorization .apiList li span {
  font-size: 0.25rem;
  color: #02172E;
}
